// import React, { useEffect, useState } from "react";
// import {
//   useForm,
//   SubmitHandler,
//   Controller,
//   useFieldArray,
//   set,
// } from "react-hook-form";
// import { Navigate, useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
// // import Logo from "/assets/image/Go Back.svg";
// import Goback from "../assets/image/GoBack.svg";
// import deleteIcon from "../assets/image/deleteIcon.svg";
// import Add from "../assets/image/Add.svg";

// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import BillingAddInputField from "./BillingAddInputField";
// import BillingAddPriceInputField from "./BillingAddPriceInputField";
// import { Alert, Autocomplete, Box, TextField } from "@mui/material";
// import { useMutation, useQuery } from "react-query";
// import axios from "axios";
// import { getSalesDetail } from "./BillingView";
// import Sidebars from "./Sidebar";
// import {
//   getCustomerRegister,
//   getLastInvoice,
//   getProductDetail,
//   getPurchaseProduct,
//   getSalesInvNo,
//   headOfficeName,
// } from "../api/GlobalApi";
// import DatePicker from "react-datepicker";
// import BillingAddProductField from "./BillingAddProductField";
// import moment from "moment/moment";
// import { v4 as uuid } from "uuid";
// // import { Modal } from "bootstrap";
// // import Box from '@mui/material/Box';
// import Modal from "@mui/material/Modal";
// import AlertModal from "./AlertModal";
// const BillingAdd = (props) => {
//   const {
//     register,
//     handleSubmit,
//     control,
//     formState: { errors },
//     setValue,
//     watch,
//   } = useForm({
//     defaultValues: {
//       userForm: [
//         {
//           Product: "",
//           descripTion: "",
//           hsd: "",
//           UnitPrice: "",
//           DiscountPrice: "",
//           Quantity: "",
//           ActualUnitPrice: "",
//           quantityType: "",
//         },
//       ],
//     },
//   });

//   const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
//     {
//       name: "userForm",
//       control,
//     }
//   );

//   const navigate = useNavigate();
//   const { state } = useLocation();
//   const [productAdd, setProductAdd] = useState("");
//   const [open, setOpen] = useState(false);
//   const [UpdateOpen, setUpdateOpen] = useState(false);
//   const [errorOpen, setErrorOpen] = useState(false);
//   const companyName = sessionStorage.getItem("head");
//   const subCompanyName = sessionStorage.getItem("sub");
//   const subCompanyList = JSON.parse(subCompanyName);
//   console.log("subCompanyList", subCompanyList?.headOff);

//   const { userForm, headOffice } = watch();
//   console.log('userForm',userForm);

//   const invoiceNumberlist = useQuery("getSalesInvNumber", getLastInvoice);

//   let billNum =
//     invoiceNumberlist && invoiceNumberlist?.data
//       ? invoiceNumberlist && invoiceNumberlist?.data
//       : "";

//   console.log("billNum", billNum);

//   useEffect(() => {
//     if (state === null) {
//       setValue("invoiceEntry", billNum + 1);
//     }
//   }, [billNum]);

//   console.log("billNum", billNum);

//   const editMrMrs = state?.customerName?.slice(0, 2);
//   const EditCustomerName = state?.customerName.substring(
//     state?.customerName.indexOf(",") + 1
//   );

//   const { data } = useQuery("getProductDetail", getProductDetail);
//   const detail = data && data?.data;
//   const filterProductName = detail?.filter(
//     (item) => item.stock !== "OutOfstock"
//   );
//   console.log("filterProductName", filterProductName?.productNameEnglish);
//   useEffect(() => {
//     if (state) {
//       // setValue("mrMrs", editMrMrs);
//       setValue("customerName", EditCustomerName);
//       setValue("contactNumber", state?.contactNumber);
//       setValue("customerAddress", state?.customerAddress);
//       setValue("pincode", state?.pincode);
//       setValue("transactionMode", state?.transactionMode);
//       setValue("transactionStatus", state?.transactionStatus);
//       setValue("salesDate", state?.salesDate);
//       setValue("headOffice", state?.headOffice);
//       setValue("subOffice", state?.subOffice);
//       setValue("district", state?.disTrict);
//       setValue("billType", state?.billType);
//       setValue("invoiceEntry", state?.invoiceEntry);
//       setValue("gst", state?.gst);
//       if (state?.userForm) {
//         remove();
//         console.log("KKFFGGGLS", state?.userForm[0].productName);
//         state.userForm.forEach((formItem, index) => {
//           append({
//             productName: formItem.productName,
//             unitPrice: formItem.unitPrice,
//             discountPrice: formItem.discountPrice,
//             quantity: formItem.quantity,
//             actualPrice: formItem.actualPrice,
//             descripTion: formItem.descripTion,
//             hsd: formItem.hsd,
//             quantityType: formItem.quantityType,
//           });
//           if (index === 0) {
//             setValue(
//               `userForm.${0}.productName`,
//               state.userForm[index].productName
//             );

//             setValue(
//               `userForm.${index}.unitPrice`,
//               state.userForm[index].unitPrice
//             );
//             setValue(
//               `userForm.${index}.discountPrice`,
//               state.userForm[index].discountPrice
//             );
//             setValue(
//               `userForm.${index}.quantity`,
//               state.userForm[index].quantity
//             );
//             setValue(
//               `userForm.${index}.actualPrice`,
//               state.userForm[index].actualPrice
//             );
//             setValue(
//               `userForm.${index}.descripTion`,
//               state.userForm[index].descripTion
//             );
//             setValue(`userForm.${index}.hsd`, state.userForm[index].hsd);
//             setValue(
//               `userForm.${index}.quantityType`,
//               state.userForm[index].quantityType
//             );
//           }
//         });
//       }
//     } else {
//       setValue("headOffice", companyName || subCompanyList?.headOff);
//       setValue("subOffice", subCompanyList?.subOff);
//       // setValue("invoiceEntry",  billNum + 1);
//     }
//   }, [state, setValue, append, remove]);

//   // useEffect(()=>{
//   //   userForm?.map((index)=>{
//   //     setValue(
//   //       `userForm.${index}.productName`,
//   //       userForm[index]?.productName
//   //     );

//   //     setValue(
//   //       `userForm.${index}.unitPrice`,
//   //       userForm[index]?.unitPrice
//   //     );
//   //     setValue(
//   //       `userForm.${index}.discountPrice`,
//   //       userForm[index]?.discountPrice
//   //     );
//   //     setValue(
//   //       `userForm.${index}.quantity`,
//   //       userForm[index]?.quantity
//   //     );
//   //     setValue(
//   //       `userForm.${index}.actualPrice`,
//   //       userForm[index]?.actualPrice
//   //     );
//   //     setValue(
//   //       `userForm.${index}.descripTion`,
//   //       userForm[index]?.descripTion
//   //     );
//   //     setValue(`userForm.${index}.hsd`, userForm[index]?.hsd);
//   //     setValue(
//   //       `userForm.${index}.quantityType`,
//   //       userForm[index]?.quantityType
//   //     );
//   //   })
//   // },[userForm])

//   const getSalesDetails = useQuery(
//     "getPurchaseProductBillingAddProduct",
//     getPurchaseProduct
//   );

//   console.log("getSalesDetails", getSalesDetails);

//   const GetcustomerRegister = useQuery(
//     "customerResisterTable",
//     getCustomerRegister
//   );
//   const getHeadOffice = useQuery("headOfficeName", headOfficeName);
//   let details =
//     GetcustomerRegister &&
//     GetcustomerRegister?.data &&
//     GetcustomerRegister?.data?.data
//       ? GetcustomerRegister &&
//         GetcustomerRegister?.data &&
//         GetcustomerRegister?.data?.data
//       : [];

//   const subOfficeName = details?.filter((item) => {
//     return item?.companyType === "subOffice";
//   });
//   console.log("subOfficeName", subOfficeName);

//   const { mutate, isLoading, isError, isSuccess } = useMutation(
//     async (postData) => {
//       try {
//         console.log("postData", postData);
//         const response = await axios.post(
//           `${process.env.REACT_APP_HOST}/sales/add`,
//           postData
//         );
//         setOpen("Sales record has been successfully added.");
//         return response.data; // Ensure you return the response data so it can be accessed in onSuccess
//       } catch (error) {
//         console.error("Error in mutation:", error);
//         setErrorOpen(
//           error &&
//             error?.response &&
//             error?.response?.data &&
//             error?.response?.data
//         );
//         throw error;
//       }
//     }
//   );

//   const postFormData = useMutation(
//     (postData) =>
//       axios.put(
//         `${process.env.REACT_APP_HOST}/sales/update/${state.id}`,
//         postData
//       ),
//     {
//       onSuccess: () => {
//         setUpdateOpen("Sales record has been successfully updated.");
//       },
//       onError: (error) => {
//         setErrorOpen(
//           error &&
//             error?.response &&
//             error?.response?.data &&
//             error?.response?.data
//         );
//       },
//     }
//   );
//   const onSubmit = (data) => {
//     const value = {
//       customerName: `${data?.mrMrs === undefined ? "" : data?.mrMrs}${
//         data?.mrMrs === undefined ? "" : "."
//       }${data?.customerName}`,
//       customerAddress: data?.customerAddress,
//       pincode: data?.pincode,
//       contactNumber: data?.contactNumber,
//       salesDate: data?.salesDate,
//       transactionMode: data?.transactionMode,
//       transactionStatus: data?.transactionStatus,
//       headOffice: data?.headOffice,
//       subOffice: data?.subOffice,
//       disTrict: data?.district,
//       billType: data?.billType,
//       gst: data?.gst,
//       invoiceEntry: data?.invoiceEntry,
//       userForm,
//     };
//     console.log(" OnSubimt", value);
//     if (state && state?.id) {
//       console.log("state?.id", value);

//       postFormData.mutate(value);
//     } else {
//       console.log("BillingData", value);
//       mutate(value);
//     }
//   };

//   let newArray =
//     getSalesDetails.data &&
//     getSalesDetails?.data?.data.filter((item) => item !== null);

//   console.log("headOffice", headOffice);

//   const getPurchaseProducts = useQuery(
//     "getPurchaseProduct",
//     getPurchaseProduct
//   );

//   const top100Films =
//     getPurchaseProducts.data &&
//     getPurchaseProducts?.data?.data?.filter((item) => item !== null);

//   console.log("getPurchaseProducts", getPurchaseProducts);

//   useEffect(() => {
//     if (productAdd === "add") {
//       navigate("/ProductView/ProductForm");
//     }
//   }, [productAdd]);

//   const watchFields = watch("userForm");
//   const calculateQuantity = (index) => {
//     const quantity = watchFields[index]?.quantity || 0;
//     const unitPrice = watchFields[index]?.unitPrice || 0;
//     const discountPrice = watchFields[index]?.discountPrice || 0;
//     const subTotal =
//       Number(quantity) * Number(unitPrice) - Number(discountPrice);
//     setValue(`userForm.${index}.actualPrice`, subTotal.toString());
//   };

//   // const handleOpen = () => {
//   //   setOpen(true);
//   // };
//   // const handleClose = () => {
//   //   setOpen(false);
//   // };
//   // const userForm=useForm()
//   console.log("KKK<<<>>>>", userForm);

//   return (
//     <>
//       <div className="main-container" style={{ width: "" }}>
//         {/* <div className="container-lg"> */}
//         <div className="BillingAddHeader">
//           <div className="Billing-Add-GoBackBtn">
//             <img src={Goback} alt="no-image" onClick={() => navigate(-1)} />
//           </div>

//           <div className="billing-heading-viewblock">
//             <div>
//               <h3 className="Billng-Add-Heading">Sales Add</h3>
//             </div>

//             <div className="Billing-Add-ViewBlock">
//               <button
//                 className="Billing-Add-ViewBtn"
//                 onClick={() => {
//                   navigate("/BillingView");
//                 }}
//               >
//                 View
//               </button>
//             </div>
//           </div>
//         </div>
//         <div
//           className="BillingAddMainBlock"
//           style={{ border: "none", overflow: "hidden" }}
//         >
//           <form
//             onSubmit={handleSubmit(onSubmit)}
//             className="BillingAddFormBlock"
//           >
//             <div>
//               <div
//                 className="BillingAddInputContainer "
//                 // style={{ justifyContent: "space-between", width: "90%" }}
//               >
//                 <div className="BillingAddInputField ">
//                   <label className="BillingAddInputLabel">Mr/Mrs</label>
//                   <Controller
//                     control={control}
//                     name="mrMrs"
//                     render={({ field: { onChange, onBlur, value, ref } }) => (
//                       <select
//                         value={value}
//                         onChange={onChange}
//                         className="billing_input_field"
//                         placeholder="Select"
//                         style={{
//                           "text-transform": "capitalize",
//                         }}
//                       >
//                         <option value=" ">None</option>
//                         <option value="Miss">Miss</option>
//                         <option value="Mr">Mr</option>
//                         <option value="Mrs">Mrs</option>
//                       </select>
//                     )}
//                   />
//                 </div>
//                 <div className="BillingAddInputField ">
//                   <label className="BillingAddInputLabel">
//                     Customer Name{" "}
//                     <span style={{ color: "red", fontSize: "14px" }}>*</span>
//                   </label>
//                   <Controller
//                     control={control}
//                     name="customerName"
//                     // render={({ field: { onChange, onBlur, value, ref } }) => (
//                     render={(field) => (
//                       <BillingAddInputField
//                         {...field}
//                         // onChange={onChange}
//                         // placeholder="Customer Name"
//                         type="text"
//                       />
//                     )}
//                     rules={{ required: true }}
//                   />
//                 </div>

//                 <div className="BillingAddInputField">
//                   <label className="BillingAddInputLabel">
//                     Customer Address
//                     <span style={{ color: "red", fontSize: "14px" }}>*</span>
//                   </label>
//                   <Controller
//                     control={control}
//                     name="customerAddress"
//                     render={({
//                       field: { onChange, onBlur, value, ref },
//                       fieldState: { error },
//                     }) => (
//                       <textarea
//                         style={error ? { borderColor: "red" } : {}}
//                         value={value}
//                         onChange={onChange}
//                         // {...field}
//                         className="billing_input_field"
//                       ></textarea>
//                     )}
//                     rules={{ required: true }}
//                   />
//                 </div>
//                 <div className="BillingAddInputField">
//                   <label className="BillingAddInputLabel">
//                     District/State{" "}
//                     <span style={{ color: "red", fontSize: "14px" }}>*</span>
//                   </label>
//                   <Controller
//                     control={control}
//                     name="district"
//                     render={(field) => (
//                       <BillingAddInputField
//                         {...field}
//                         // placeholder="603103"
//                         type="text"
//                       />
//                     )}
//                     rules={{ required: true }}
//                   />
//                 </div>

//                 <div className="BillingAddInputField">
//                   <label className="BillingAddInputLabel">
//                     Pincode
//                     <span style={{ color: "red", fontSize: "14px" }}>*</span>
//                   </label>
//                   <Controller
//                     control={control}
//                     name="pincode"
//                     render={(field) => (
//                       <BillingAddInputField
//                         {...field}
//                         // placeholder="603103"
//                         type="number"
//                       />
//                     )}
//                     rules={{ required: true }}
//                   />
//                 </div>

//                 <div className="BillingAddInputField">
//                   <label className="BillingAddInputLabel">
//                     Phone Number
//                     <span style={{ color: "red", fontSize: "14px" }}>*</span>
//                   </label>
//                   <Controller
//                     control={control}
//                     name="contactNumber"
//                     render={(field) => (
//                       <BillingAddInputField
//                         {...field}
//                         // placeholder="contact"
//                         type="text"
//                       />
//                     )}
//                     rules={{ required: true }}
//                   />
//                 </div>

//                 <div className="BillingAddInputField">
//                   <label className="BillingAddInputLabel">
//                     Bill Type
//                     <span style={{ color: "red", fontSize: "14px" }}>*</span>
//                   </label>
//                   <Controller
//                     control={control}
//                     name="billType"
//                     render={({
//                       field: { onChange, onBlur, value, ref },
//                       fieldState: { error },
//                     }) => (
//                       <select
//                         value={value}
//                         onChange={onChange}
//                         className="text-capitalize billing_input_field"
//                         placeholder="Select"
//                         helperText={error ? "This field is required" : ""}
//                         // style={{
//                         //   "text-transform": "capitalize",
//                         // }}
//                         style={error ? { borderColor: "red" } : {}}
//                       >
//                         <option value=""></option>
//                         <option value="Tax Invoice">Tax Invoice</option>
//                         <option value="Quotation">Quotation</option>
//                         <option value="Proforma Invoice">
//                           Proforma Invoice
//                         </option>
//                         {error ? "This field is required" : ""}
//                       </select>
//                     )}
//                     rules={{ required: true }}
//                   />
//                 </div>

//                 <div className="BillingAddInputField">
//                   <label className="BillingAddInputLabel">
//                     Gst No
//                     {/* <span style={{ color: "red", fontSize: "14px" }}>*</span> */}
//                   </label>
//                   <Controller
//                     control={control}
//                     name="gst"
//                     render={(field) => (
//                       <BillingAddInputField
//                         {...field}
//                         // placeholder="contact"
//                         type="text"
//                       />
//                     )}
//                     // rules={{ required/: true }}
//                   />
//                 </div>
//                 <div className="BillingAddInputField">
//                   <label className="BillingAddInputLabel">
//                     Bill No
//                     <span style={{ color: "red", fontSize: "14px" }}>*</span>
//                   </label>
//                   <Controller
//                     control={control}
//                     name="invoiceEntry"
//                     render={({
//                       field: { onChange, onBlur, value, ref },
//                       fieldState: { error },
//                     }) => (
//                       <input
//                         type="text"
//                         value={value}
//                         onChange={onChange}
//                         className="text-capitalize billing_input_field"
//                         // disabled
//                       />
//                     )}
//                     rules={{ required: true }}
//                   />
//                 </div>
//                 <div
//                   className="BillingAddInputField"
//                   style={{ paddingRight: "12px" }}
//                 >
//                   <label className="BillingAddInputLabel">Sales Date</label>
//                   <Controller
//                     control={control}
//                     name="salesDate"
//                     render={({ field }) => (
//                       <DatePicker
//                         className="billing_input_field "
//                         {...field}
//                         // placeholderText="Sales Date"
//                         showYearDropdown
//                         selected={field.value}
//                         onChange={(date) => field.onChange(date)}
//                       />
//                     )}
//                   />
//                 </div>
//                 <div className="BillingAddInputField ">
//                   <label className="BillingAddInputLabel">
//                     Transaction Mode
//                     <span style={{ color: "red", fontSize: "14px" }}>*</span>
//                   </label>
//                   <Controller
//                     control={control}
//                     name="transactionMode"
//                     render={({
//                       field: { onChange, onBlur, value, ref },
//                       fieldState: { error },
//                     }) => (
//                       <select
//                         value={value}
//                         onChange={onChange}
//                         className="billing_input_field"
//                         // placeholder="Select"
//                         // style={{
//                         //   "text-transform": "capitalize",
//                         // }}
//                         style={error ? { borderColor: "red" } : {}}
//                       >
//                         <option value=""></option>
//                         <option value="Cash">Cash</option>
//                         <option value="Online">Online</option>
//                         <option value="credit">credit</option>
//                       </select>
//                     )}
//                     rules={{ required: true }}
//                   />
//                 </div>

//                 <div className="BillingAddInputField ">
//                   <label className="BillingAddInputLabel">
//                     Transaction Status
//                     <span style={{ color: "red", fontSize: "14px" }}>*</span>
//                   </label>
//                   <Controller
//                     control={control}
//                     name="transactionStatus"
//                     render={({
//                       field: { onChange, onBlur, value, ref },
//                       fieldState: { error },
//                     }) => (
//                       <select
//                         value={value}
//                         onChange={onChange}
//                         className=" text-capitalize billing_input_field"
//                         placeholder="Select"
//                         // style={{
//                         //   "text-transform": "capitalize",
//                         // }}
//                         style={error ? { borderColor: "red" } : {}}
//                       >
//                         <option value=""></option>
//                         <option value="Completed">completed</option>
//                         <option value="Pending">pending</option>
//                       </select>
//                     )}
//                     rules={{ required: true }}
//                   />
//                 </div>
//                 {(companyName || subCompanyName) && (
//                   <div className="BillingAddInputField ">
//                     <label className="BillingAddInputLabel">Head Office</label>
//                     <Controller
//                       control={control}
//                       name="headOffice"
//                       render={({ field: { onChange, onBlur, value, ref } }) => (
//                         <input
//                           type="text"
//                           className="billing_input_field"
//                           value={value}
//                         />
//                       )}
//                       // rules={{ required: true }}
//                     />
//                   </div>
//                 )}
//                 {/* {companyName === null && ( */}
//                 {companyName ? (
//                   <div className="BillingAddInputField ">
//                     <label className="BillingAddInputLabel">
//                       Branch Office
//                     </label>
//                     <Controller
//                       control={control}
//                       name="subOffice"
//                       render={({ field: { onChange, onBlur, value, ref } }) => (
//                         <select
//                           value={value}
//                           onChange={onChange}
//                           className="billing_input_field"
//                           // placeholder="Select"
//                           style={{
//                             "text-transform": "capitalize",
//                           }}
//                         >
//                           <option value=""></option>
//                           {subOfficeName?.map((data, index) => (
//                             <option key={index} value={data?.shopName}>
//                               {data.shopName}
//                             </option>
//                           ))}
//                         </select>
//                       )}
//                       // rules={{ required: true }}
//                     />
//                   </div>
//                 ) : (
//                   <div className="BillingAddInputField ">
//                     <label className="BillingAddInputLabel">
//                       Branch Office
//                     </label>
//                     <Controller
//                       control={control}
//                       name="subOffice"
//                       render={({ field: { onChange, onBlur, value, ref } }) => (
//                         <input
//                           type="text"
//                           className="billing_input_field"
//                           value={value}
//                         />
//                       )}
//                       // rules={{ required: true }}
//                     />
//                   </div>
//                 )}
//               </div>

//               {fields.map((field, index) => {
//                 console.log('field',field)
//                 let addSalesValues =
//                   Number(userForm && userForm[index]?.UnitPrice) *
//                     Number(userForm[index]?.Quantity) -
//                   Number(userForm[index]?.DiscountPrice);
//                 console.log("USEFORM_addSalesValues", addSalesValues);
//                 userForm[index].ActualUnitPrice = addSalesValues;
//                 return (
//                   <div className="d-flex flex-wrap">
//                     {/* <div className="BillingAddAppendMainBlock"> */}
//                     <div className="BillingAddAppendField">
//                       <div className="BillingAddAppendInputContainer">
//                         <label className="BillingAddInputLabels">
//                           Product
//                           <span style={{ color: "red", fontSize: "14px" }}>
//                             *
//                           </span>
//                         </label>
//                         <div>
//                           <Controller
//                             control={control}
//                             name={`userForm.${index}.productName`}
//                             render={({
//                               field: { onChange, value },
//                               fieldState: { error },
//                             }) => (
//                               <Autocomplete
//                                 value={value && value ? value : ""}
//                                 // value={value}
//                                 onChange={(event, newValue) => {
//                                   onChange(newValue);
//                                 }}
//                                 className="purchaseSelect_input_field css-2avex9-MuiAutocomplete-root .MuiOutlinedInput-root"
//                                 disablePortal
//                                 id="combo-box-demo"
//                                 options={
//                                   filterProductName
//                                     ? filterProductName.map((data) => {
//                                         return data?.productNameEnglish;
//                                       })
//                                     : []
//                                 }
//                                 sx={{ height: 40 }}
//                                 renderInput={(params) => (
//                                   <TextField
//                                     {...params}
//                                     value={value && value ? value : "none"}
//                                     onChange={(e) => onChange(e.target.value)}
//                                     error={!!error}
//                                     // helperText={
//                                     //   error ? "This field is required" : ""
//                                     // }
//                                   />
//                                 )}
//                               />
//                             )}
//                             rules={{ required: true }}
//                           />
//                         </div>
//                         {/* <input {...register(`userForm.${index}.Product`)} ></input> */}
//                       </div>
//                       <div className="BillingAddAppendInputContainer">
//                         <label className=" BillingAddInputLabels">
//                           Services
//                         </label>
//                         <Controller
//                           control={control}
//                           name={`userForm.${index}.descripTion`}
//                           // name="customerAddress"
//                           render={({
//                             field: { onChange, onBlur, value, ref },
//                             fieldState: { error },
//                           }) => (
//                             <textarea
//                               value={value}
//                               onChange={onChange}
//                               // {...field}
//                               className="billing_input_field"
//                             ></textarea>
//                           )}
//                           // rules={{ required: true }}
//                         />
//                       </div>
//                       <div className="BillingAddAppendInputContainer">
//                         <label className="BillingAddInputLabels ">HSN CD</label>
//                         <Controller
//                           control={control}
//                           name={`userForm.${index}.hsd`}
//                           // name="quantity"
//                           render={({ field: { onChange, value } }) => (
//                             <input
//                               {...field}
//                               // placeholder="Quantity"
//                               className="billing_input_field"
//                               type="text"
//                               value={value}
//                               onChange={onChange}
//                               // onChange={(e) => {
//                               //   onChange(e);
//                               //   // calculateQuantity(index);
//                               // }}
//                             />
//                           )}
//                         />
//                       </div>
//                       <div className="BillingAddAppendInputContainer">
//                         <label className="BillingAddInputLabels ">
//                           Quantity
//                           <span style={{ color: "red", fontSize: "14px" }}>
//                             *
//                           </span>
//                         </label>
//                         <Controller
//                           control={control}
//                           name={`userForm.${index}.quantity`}
//                           // name="quantity"
//                           render={({
//                             field: { onChange, onBlur, value, ref },
//                             fieldState: { error },
//                           }) => (
//                             <input
//                               {...field}
//                               style={error ? { borderColor: "red" } : {}}
//                               // placeholder="Quantity"
//                               className="billing_input_field"
//                               type="number"
//                               value={value}
//                               onChange={(e) => {
//                                 onChange(e);
//                                 calculateQuantity(index);
//                               }}
//                             />
//                           )}
//                           rules={{ required: true }}
//                         />
//                       </div>
//                       <div className="BillingAddAppendInputContainer">
//                         <label className="BillingAddInputLabels">
//                           Quantity Type
//                           <span style={{ color: "red", fontSize: "14px" }}>
//                             *
//                           </span>
//                         </label>
//                         <Controller
//                           control={control}
//                           name={`userForm.${index}.quantityType`}
//                           render={({
//                             field: { onChange, onBlur, value, ref },
//                             fieldState: { error },
//                           }) => (
//                             <select
//                               value={value}
//                               className="ps-0 billing_input_field "
//                               onChange={onChange}
//                               style={error ? { borderColor: "red" } : {}}
//                             >
//                               <option value=""> </option>
//                               {[
//                                 ...new Set(
//                                   detail?.map((data) => data.quantityType)
//                                 ),
//                               ].map((quantityType, index) => (
//                                 <option key={index} value={quantityType}>
//                                   {quantityType}
//                                 </option>
//                               ))}
//                               {/* {detail?.map((data, index) => (
//                                 <option key={index} value={data.quantityType}>
//                                   {data.quantityType}
//                                 </option>
//                               ))} */}
//                             </select>
//                           )}
//                           rules={{ required: true }}
//                         />
//                       </div>
//                       <div className="BillingAddAppendInputContainer">
//                         <label className="BillingAddInputLabels">
//                           Unit Price
//                           <span style={{ color: "red", fontSize: "14px" }}>
//                             *
//                           </span>
//                         </label>
//                         <Controller
//                           control={control}
//                           // name="unitPrice"
//                           name={`userForm.${index}.unitPrice`}
//                           render={({
//                             field: { onChange, onBlur, value, ref },
//                             fieldState: { error },
//                           }) => (
//                             <input
//                               style={error ? { borderColor: "red" } : {}}
//                               {...field}
//                               // placeholder="Unite Price"
//                               className="billing_input_field"
//                               type="number"
//                               value={value}
//                               onChange={(e) => {
//                                 onChange(e);
//                                 calculateQuantity(index);
//                               }}
//                             />
//                             // render={(field) => (
//                             // <BillingAddProductField
//                             //   // billingInputCss="--billingInputCss"
//                             //   // className='billingAddProductField'
//                             //   {...field}
//                             //   placeholder="0.00"
//                             //   type="number"
//                             // />
//                           )}
//                           rules={{ required: true }}
//                         />
//                         {/* <input {...register()} type="number"></input> */}
//                       </div>

//                       <div className="BillingAddAppendInputContainer">
//                         <label className="BillingAddInputLabels">
//                           Discount Price
//                         </label>
//                         <Controller
//                           control={control}
//                           name={`userForm.${index}.discountPrice`}
//                           // name="discountPrice"
//                           className="discount"
//                           render={({ field: { onChange, value } }) => (
//                             <input
//                               {...field}
//                               // placeholder="Discount Price"
//                               className="billing_input_field"
//                               type="number"
//                               value={value}
//                               onChange={(e) => {
//                                 onChange(e);
//                                 calculateQuantity(index);
//                               }}
//                             />
//                           )}
//                         />
//                         {/* <input {...register(`userForm.${index}.DiscountPrice`)} type="number"></input> */}
//                       </div>

//                       <div className="BillingAddAppendInputContainer">
//                         <label className="BillingAddInputLabels">
//                           Actual Unit Price
//                         </label>
//                         <Controller
//                           control={control}
//                           name={`userForm.${index}.actualPrice`}
//                           // name="actualPrice"
//                           defaultValue={addSalesValues}
//                           render={({ field: { onChange, value } }) => (
//                             <input
//                               {...field}
//                               // placeholder="Actual Price"
//                               className="billing_input_field"
//                               type="number"
//                               value={value}
//                               onChange={(e) => {
//                                 onChange(e);
//                                 calculateQuantity(index);
//                               }}
//                             />
//                           )}
//                         />
//                       </div>
//                       <div className="d-flex align-items-center">
//                         {index !== 0 ? (
//                           <img
//                             style={{ width: "40px", height: "45px" }}
//                             src={deleteIcon}
//                             alt="DELETE"
//                             {...register(`userForm.${index}.image`)}
//                             onClick={() => remove(index)}
//                           />
//                         ) : (
//                           <></>
//                         )}
//                         <div>
//                           {index < 0 ? (
//                             <></>
//                           ) : (
//                             <>
//                               <div
//                                 style={{
//                                   height: "100px",
//                                   display: "flex",
//                                   alignItems: "center",
//                                   marginLeft: "10px",
//                                 }}
//                               >
//                                 <img
//                                   src={Add}
//                                   alt=""
//                                   className=" BillingAdd-AddBtn"
//                                   onClick={() => {
//                                     append({
//                                       Product: " ",
//                                       UnitPrice: 0,
//                                       DiscountPrice: 0,
//                                       Quantity: 0,
//                                       ActualUnitPrice: 0,
//                                       quantityType: " ",
//                                     });
//                                   }}
//                                 ></img>
//                               </div>
//                             </>
//                           )}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 );
//               })}
//               <div />
//             </div>

//             <div className="ms-3 BillingAddSubmitBlock">
//               <div>
//                 <button type="submit" className="BillingAddSubmitBtn">
//                   {isLoading ? (
//                     <span
//                       class="spinner-border spinner-border-sm"
//                       role="status"
//                       aria-hidden="true"
//                     ></span>
//                   ) : (
//                     "save"
//                   )}
//                 </button>
//               </div>
//             </div>
//             {/* </div> */}
//           </form>
//         </div>
//       </div>
//       {/* </div> */}
//       <AlertModal
//         open={open}
//         setOpen={setOpen}
//         // handleClose={handleClose}
//         setUpdateOpen={setUpdateOpen}
//         UpdateOpen={UpdateOpen}
//         navigate={"/BillingView"}
//         setErrorOpen={setErrorOpen}
//         errorOpen={errorOpen}
//       />
//     </>
//   );
// };

// export default BillingAdd;

import React, { useEffect, useState } from "react";
import {
  useForm,
  SubmitHandler,
  Controller,
  useFieldArray,
  set,
} from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import Logo from "/assets/image/Go Back.svg";
import Goback from "../assets/image/GoBack.svg";
import deleteIcon from "../assets/image/deleteIcon.svg";
import Add from "../assets/image/Add.svg";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import BillingAddInputField from "./BillingAddInputField";
import BillingAddPriceInputField from "./BillingAddPriceInputField";
import { Alert, Autocomplete, Box, TextField } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { getSalesDetail } from "./BillingView";
import Sidebars from "./Sidebar";
import {
  getCustomerRegister,
  getLastInvoice,
  getProductDetail,
  getPurchaseProduct,
  getSalesInvNo,
  headOfficeName,
} from "../api/GlobalApi";
import DatePicker from "react-datepicker";
import BillingAddProductField from "./BillingAddProductField";
import moment from "moment/moment";
import { v4 as uuid } from "uuid";
// import { Modal } from "bootstrap";
// import Box from '@mui/material/Box';
import Modal from "@mui/material/Modal";
import AlertModal from "./AlertModal";
const BillingAdd = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      userForm: [
        {
          Product: "",
          descripTion: "",
          hsd: "",
          UnitPrice: "",
          DiscountPrice: "",
          Quantity: "",
          ActualUnitPrice: "",
          quantityType: "",
        },
      ],
    },
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      name: "userForm",
      control,
    }
  );

  const navigate = useNavigate();
  const { state } = useLocation();
  const [productAdd, setProductAdd] = useState("");
  const [open, setOpen] = useState(false);
  const [UpdateOpen, setUpdateOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(false);
  const [branchValue, setBranchValue] = useState([]);
  const companyName = sessionStorage.getItem("head");
  const subCompanyName = sessionStorage.getItem("sub");
  const subCompanyList = JSON.parse(subCompanyName);
  console.log("BranchValue", branchValue);

  const invoiceNumberlist = useQuery("getSalesInvNumber", getLastInvoice);

  let billNum =
    invoiceNumberlist && invoiceNumberlist?.data
      ? invoiceNumberlist && invoiceNumberlist?.data
      : "";

  console.log("billNum", billNum);

  useEffect(() => {
    if (state === null) {
      setValue("invoiceEntry", billNum + 1);
    }
  }, [billNum]);

  const editMrMrs = state?.customerName?.slice(0, 2);
  const EditCustomerName = state?.customerName.substring(
    state?.customerName.indexOf(",") + 1
  );

  const { data } = useQuery("getProductDetail", getProductDetail);
  const detail = data && data?.data;
  const filterProductName = detail?.filter(
    (item) => item.stock !== "OutOfstock"
  );
  console.log("filterProductName", state);

  useEffect(() => {
    if (state) {
      // setValue("mrMrs", editMrMrs);
      console.log("state?.userForm", state?.userForm);

      setValue("customerName", EditCustomerName);
      setValue("contactNumber", state?.contactNumber);
      setValue("customerAddress", state?.customerAddress);
      setValue("pincode", state?.pincode);
      setValue("transactionMode", state?.transactionMode);
      setValue("transactionStatus", state?.transactionStatus);
      setValue("salesDate", state?.salesDate);
      setValue("headOffice", state?.headOffice);
      setValue("subOffice", state?.subOffice);
      setValue("district", state?.disTrict);
      setValue("billType", state?.billType);
      setValue("invoiceEntry", state?.invoiceEntry);
      setValue("gst", state?.gst);
      setValue("gstType", state?.gstType);
      setValue("gstPercentage", state?.gstPercentage);
      setBranchValue(state?.userForm);
    } else {
      setValue("headOffice", companyName || subCompanyList?.headOff);
      setValue("subOffice", subCompanyList?.subOff);
      // setValue("invoiceEntry", billNum + 1);
    }
  }, [state, setValue, append, remove]);

  const getSalesDetails = useQuery(
    "getPurchaseProductBillingAddProduct",
    getPurchaseProduct
  );

  console.log("getSalesDetails", getSalesDetails);

  const GetcustomerRegister = useQuery(
    "customerResisterTable",
    getCustomerRegister
  );
  const getHeadOffice = useQuery("headOfficeName", headOfficeName);
  let details =
    GetcustomerRegister &&
    GetcustomerRegister?.data &&
    GetcustomerRegister?.data?.data
      ? GetcustomerRegister &&
        GetcustomerRegister?.data &&
        GetcustomerRegister?.data?.data
      : [];

  const subOfficeName = details?.filter((item) => {
    return item?.companyType === "subOffice";
  });
  console.log("subOfficeName", subOfficeName);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    async (postData) => {
      try {
        console.log("postData", postData);
        const response = await axios.post(
          `${process.env.REACT_APP_HOST}/sales/add`,
          postData
        );
        setOpen("Sales record has been successfully added.");
        return response.data; // Ensure you return the response data so it can be accessed in onSuccess
      } catch (error) {
        console.error("Error in mutation:", error);
        setErrorOpen(
          error &&
            error?.response &&
            error?.response?.data &&
            error?.response?.data
        );
        throw error;
      }
    }
  );

  const postFormData = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/sales/update/${state.id}`,
        postData
      ),
    {
      onSuccess: () => {
        // alert("Update successful!");
        setUpdateOpen("Sales record has been successfully updated.");
        // navigate("/BillingView");
      },
      onError: (error) => {
        // console.error("Mutation failed:", error);
        setErrorOpen(
          error &&
            error?.response &&
            error?.response?.data &&
            error?.response?.data
        );
      },
    }
  );

  const onSubmit = (data) => {
    console.log("submitData", data);

    const value = {
      customerName: `${data?.mrMrs === undefined ? "" : data?.mrMrs}${
        data?.mrMrs === undefined ? "" : "."
      }${data?.customerName}`,
      customerAddress: data?.customerAddress,
      pincode: data?.pincode,
      contactNumber: data?.contactNumber,
      salesDate: data?.salesDate,
      transactionMode: data?.transactionMode,
      transactionStatus: data?.transactionStatus,
      headOffice: data?.headOffice,
      subOffice: data?.subOffice,
      disTrict: data?.district,
      billType: data?.billType,
      gst: data?.gst,
      gstType: data?.gstType,
      gstPercentage: data?.gstPercentage,
      invoiceEntry: data?.invoiceEntry,
      userForm,
    };
    console.log(" OnSubimt", value);
    if (state && state?.id) {
      console.log("state?.id", value);
      postFormData.mutate(value);
    } else {
      console.log("BillingData", value);
      mutate(value);
    }
  };

  let newArray =
    getSalesDetails.data &&
    getSalesDetails?.data?.data.filter((item) => item !== null);

  const { userForm, gstType } = watch();
  console.log("gstType", gstType);

  const getProductName = watch("userForm");

  // useEffect(()=>{

  // })

  const productDetails = userForm.map((data, index) => {
    //
    return data.productName;
  });
  let max;
  let ins;
  if (productDetails) {
    productDetails.forEach((e, i) => {
      if (e) {
        max = e;
        ins = i;
      }
    });
  }
  console.log("max_productDetails", max, productDetails, branchValue, ins);

  const branchAPI = async ({ params }) => {
    console.log("params?.getProductName", params?.getProductName);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST}/products/products/${params?.getProductName}`
      );
      // const updatedData = branchValue.map(
      //   (item, i) => console.log("response.data", params?.index, i)
      //   // i === params?.index ? { ...item, index : response.data } : item,
      // );
      setBranchValue([...branchValue, response.data]);
      // console.log("response.data", updatedData);

      return response.data;
    } catch (error) {
      // throw new Error("Failed to fetch data");
    }
    // });
  };
  const productList = useQuery(
    ["user", max, ins],
    () => branchAPI({ params: { getProductName: max, index: ins } }),
    {
      enabled: shouldFetch,
      onSettled: () => setShouldFetch(false),
      onSuccess: (data) => {
        console.log("DATA_LOGINSUCESS", data);
        console.log();
        // setShouldFetch(false);
      },
      onError: (data) => {
        console.log("onError", data);
        // setShouldFetch(false);
      },
    }
  );
  console.log("productList", productList);
  const [isupdate, setIsUpdate] = useState(false);
  const removeTask = (indexToRemove) => {
    const array = branchValue.filter((item, index) => index !== indexToRemove);
    console.log("array", indexToRemove, array);
    remove(indexToRemove);
    setBranchValue(array);
    setIsUpdate(true);
  };

  console.log("formItem", branchValue);
  useEffect(() => {
    if (branchValue && !isupdate) {
      branchValue &&
        branchValue?.map((formItem, index) => {
          console.log("NNSSSS", formItem);

          setValue(
            `userForm.${index}.productName`,
            branchValue[index].productName
              ? branchValue[index].productName
              : branchValue[index].productNameEnglish
          );
          // setValue(
          //   `userForm.${index}.unitPrice`,
          //   state?.userForm[index]?.unitPrice
          //     ? state?.userForm[index]?.unitPrice
          //     : branchValue[index]
          //         .unitPrice`userForm.${index}.unitPrice`,
          //   branchValue[index]?.unitPrice
          // );
          setValue(
            `userForm.${index}.quantityType`,
            branchValue[index].quantityType
          );
          setValue(`userForm.${index}.unitPrice`, branchValue[index].unitPrice);

          // if (
          //   branchValue?.length <= 2 &&
          //   branchValue[0]?.id === branchValue[1]?.id
          // ) {
          //   const indexRemove = branchValue.splice(index, 1);
          //   console.log("indexRemove", indexRemove);
          //   setBranchValue(indexRemove);
          // }
        });
    }
    if (state?.userForm && branchValue) {
      remove();
      console.log("KKFFGGGLS", state?.userForm);
      let test;
      // let test=state?.userForm.slice(0,-1)

      branchValue.forEach((formItem, index) => {
        console.log("LL<<<", formItem, "branchValue", branchValue);
        append({
          productName: formItem.productName,
          unitPrice: formItem.unitPrice,
          discountPrice: formItem.discountPrice,
          quantity: formItem.quantity,
          actualPrice: formItem && formItem?.actualPrice,
          descripTion: formItem.descripTion,
          hsd: formItem.hsd,
          quantityType: formItem.quantityType,
        });
        if (index === 0) {
          setValue(
            `userForm.${index}.productName`,
            branchValue[index].productName
          );

          setValue(`userForm.${index}.unitPrice`, branchValue[index].unitPrice);
          setValue(
            `userForm.${index}.discountPrice`,
            branchValue[index].discountPrice
          );
          setValue(`userForm.${index}.quantity`, branchValue[index].quantity);
          setValue(
            `userForm.${index}.actualPrice`,
            branchValue[index].actualPrice
          );
          setValue(
            `userForm.${index}.descripTion`,
            branchValue[index].descripTion
          );
          setValue(`userForm.${index}.hsd`, branchValue[index].hsd);
          setValue(
            `userForm.${index}.quantityType`,
            branchValue[index].quantityType
          );
        }
      });
    }
  }, [state, branchValue, isupdate]);
  const getPurchaseProducts = useQuery(
    "getPurchaseProduct",
    getPurchaseProduct
  );

  const top100Films =
    getPurchaseProducts.data &&
    getPurchaseProducts?.data?.data?.filter((item) => item !== null);

  console.log("getPurchaseProducts", getPurchaseProducts);

  useEffect(() => {
    if (productAdd === "add") {
      navigate("/ProductView/ProductForm");
    }
  }, [productAdd]);

  const watchFields = watch("userForm");
  console.log("watchFields", watchFields);

  const calculateQuantity = (index) => {
    const updatedProducts = [...branchValue];
    const quantity = watchFields[index]?.quantity || 0;
    const unitPrice = watchFields[index]?.unitPrice || 0;
    const discountPrice = watchFields[index]?.discountPrice || 0;
    const subTotal =
      Number(quantity) * Number(unitPrice) - Number(discountPrice);
    if (subTotal > 0) {
      console.log("subTotal", subTotal);
      setValue(`userForm.${index}.actualPrice`, subTotal.toString());
    }
    // Only update the quantity for index 1, keeping everything else the same
    updatedProducts[index] = {
      ...updatedProducts[index], // Keep all other properties the same
      quantity: watchFields[index]?.quantity, // Update the quantity to 8
      // actualUnitPrice: watchFields[index]?.unitPrice,   // Update the quantity to 8
      discountPrice: watchFields[index]?.discountPrice, // Update the quantity to 8
      actualPrice: watchFields[index]?.subTotal, // Update the quantity to 8
    };
    // let demo=branchValue.map((ele,i)=>{
    //   if(index==i){
    //     return ele?.quantity
    //   }
    // })
    setBranchValue(updatedProducts);
  };

  // console.log("watchFields", watchFields[0].productName);

  // const handleOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const userForm=useForm()
  console.log("KKK<<<>>>>", userForm);
  const handlerboard = (e, index) => {
    const { name, value } = e.target;
    console.log("KKKFFF", name, value);

    // Update the specific object by index and key
    // const updatedData = data.map((item, i) =>
    //   i === index ? { ...item, [name]: value } : item
    // );
  };
  return (
    <>
      <div className="main-container" style={{ width: "" }}>
        {/* <div className="container-lg"> */}
        <div className="BillingAddHeader">
          <div className="Billing-Add-GoBackBtn">
            <img src={Goback} alt="no-image" onClick={() => navigate(-1)} />
          </div>

          <div className="billing-heading-viewblock">
            <div>
              <h3 className="Billng-Add-Heading">Sales Add</h3>
            </div>

            <div className="Billing-Add-ViewBlock">
              <button
                className="Billing-Add-ViewBtn"
                onClick={() => {
                  navigate("/BillingView");
                }}
              >
                View
              </button>
            </div>
          </div>
        </div>
        <div
          className="BillingAddMainBlock"
          style={{ border: "none", overflow: "hidden" }}
        >
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="BillingAddFormBlock"
          >
            <div>
              <div
                className="BillingAddInputContainer"
                // style={{ justifyContent: "space-between", width: "90%" }}
              >
                <div className="BillingAddInputField ">
                  <label className="BillingAddInputLabel">Mr/Mrs</label>
                  <Controller
                    control={control}
                    name="mrMrs"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        value={value}
                        onChange={onChange}
                        className="billing_input_field"
                        placeholder="Select"
                        style={{
                          "text-transform": "capitalize",
                        }}
                      >
                        <option value=" ">None</option>
                        <option value="Miss">Miss</option>
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                      </select>
                    )}
                  />
                </div>
                <div className="BillingAddInputField ">
                  <label className="BillingAddInputLabel">
                    Customer Name{" "}
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="customerName"
                    // render={({ field: { onChange, onBlur, value, ref } }) => (
                    render={(field) => (
                      <BillingAddInputField
                        {...field}
                        // onChange={onChange}
                        // placeholder="Customer Name"
                        type="text"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>

                <div className="BillingAddInputField">
                  <label className="BillingAddInputLabel">
                    Customer Address
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="customerAddress"
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error },
                    }) => (
                      <textarea
                        style={error ? { borderColor: "red" } : {}}
                        value={value}
                        onChange={onChange}
                        // {...field}
                        className="billing_input_field"
                      ></textarea>
                    )}
                    rules={{ required: true }}
                  />
                </div>
                <div className="BillingAddInputField">
                  <label className="BillingAddInputLabel">
                    District/State{" "}
                    {/* <span style={{ color: "red", fontSize: "14px" }}>*</span> */}
                  </label>
                  <Controller
                    control={control}
                    name="district"
                    render={(field) => (
                      <BillingAddInputField
                        {...field}
                        // placeholder="603103"
                        type="text"
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>

                <div className="BillingAddInputField">
                  <label className="BillingAddInputLabel">
                    Pincode
                    {/* <span style={{ color: "red", fontSize: "14px" }}>*</span> */}
                  </label>
                  <Controller
                    control={control}
                    name="pincode"
                    render={(field) => (
                      <BillingAddInputField
                        {...field}
                        // placeholder="603103"
                        type="number"
                      />
                    )}
                    // rules={{ required: true }}
                  />
                </div>

                <div className="BillingAddInputField">
                  <label className="BillingAddInputLabel">
                    Phone Number
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="contactNumber"
                    render={(field) => (
                      <BillingAddInputField
                        {...field}
                        // placeholder="contact"
                        type="text"
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>

                <div className="BillingAddInputField">
                  <label className="BillingAddInputLabel">
                    Bill Type
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="billType"
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error },
                    }) => (
                      <select
                        value={value}
                        onChange={onChange}
                        className="text-capitalize billing_input_field"
                        placeholder="Select"
                        helperText={error ? "This field is required" : ""}
                        // style={{
                        //   "text-transform": "capitalize",
                        // }}
                        style={error ? { borderColor: "red" } : {}}
                      >
                        <option value=""></option>
                        <option value="Tax Invoice">Tax Invoice</option>
                        <option value="Quotation">Quotation</option>
                        <option value="Proforma Invoice">
                          Proforma Invoice
                        </option>
                        {error ? "This field is required" : ""}
                      </select>
                    )}
                    rules={{ required: true }}
                  />
                </div>

                <div className="BillingAddInputField">
                  <label className="BillingAddInputLabel">
                    GST No
                    {/* <span style={{ color: "red", fontSize: "14px" }}>*</span> */}
                  </label>
                  <Controller
                    control={control}
                    name="gst"
                    render={(field) => (
                      <BillingAddInputField
                        {...field}
                        // placeholder="contact"
                        type="text"
                      />
                    )}
                    // rules={{ required/: true }}
                  />
                </div>
                <div className="BillingAddInputField ">
                  <label className="BillingAddInputLabel">GST Type</label>
                  <Controller
                    control={control}
                    name="gstType"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        value={value}
                        onChange={onChange}
                        className="billing_input_field"
                        placeholder="Select"
                        style={{
                          "text-transform": "capitalize",
                        }}
                      >
                        <option value="">None</option>
                        <option value="national">national</option>
                        <option value="international">international</option>
                      </select>
                    )}
                  />
                </div>
                {gstType ? (
                  <div className="BillingAddInputField ">
                    <label className="BillingAddInputLabel">
                      GST %{" "}
                      <span style={{ color: "red", fontSize: "14px" }}>*</span>
                    </label>
                    <Controller
                      control={control}
                      name="gstPercentage"
                      render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error },
                      }) => (
                        <input
                          type="text"
                          value={value}
                          onChange={onChange}
                          className="text-capitalize billing_input_field"
                          // disabled
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>
                ) : (
                  ""
                )}

                <div className="BillingAddInputField">
                  <label className="BillingAddInputLabel">
                    Bill No
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="invoiceEntry"
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error },
                    }) => (
                      <input
                        type="text"
                        value={value}
                        onChange={onChange}
                        className="text-capitalize billing_input_field"
                        // disabled
                      />
                    )}
                    rules={{ required: true }}
                  />
                </div>
                <div
                  className="BillingAddInputField"
                  style={{ paddingRight: "12px" }}
                >
                  <label className="BillingAddInputLabel">Sales Date</label>
                  <Controller
                    control={control}
                    name="salesDate"
                    render={({ field }) => (
                      <DatePicker
                        className="billing_input_field "
                        {...field}
                        // placeholderText="Sales Date"
                        showYearDropdown
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                      />
                    )}
                  />
                </div>
                <div className="BillingAddInputField ">
                  <label className="BillingAddInputLabel">
                    Transaction Mode
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="transactionMode"
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error },
                    }) => (
                      <select
                        value={value}
                        onChange={onChange}
                        className="billing_input_field"
                        // placeholder="Select"
                        // style={{
                        //   "text-transform": "capitalize",
                        // }}
                        style={error ? { borderColor: "red" } : {}}
                      >
                        <option value=""></option>
                        <option value="Cash">Cash</option>
                        <option value="Online">Online</option>
                        <option value="credit">credit</option>
                      </select>
                    )}
                    rules={{ required: true }}
                  />
                </div>

                <div className="BillingAddInputField ">
                  <label className="BillingAddInputLabel">
                    Transaction Status
                    <span style={{ color: "red", fontSize: "14px" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="transactionStatus"
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error },
                    }) => (
                      <select
                        value={value}
                        onChange={onChange}
                        className=" text-capitalize billing_input_field"
                        placeholder="Select"
                        // style={{
                        //   "text-transform": "capitalize",
                        // }}
                        style={error ? { borderColor: "red" } : {}}
                      >
                        <option value=""></option>
                        <option value="Completed">completed</option>
                        <option value="Pending">pending</option>
                      </select>
                    )}
                    rules={{ required: true }}
                  />
                </div>
                {(companyName || subCompanyName) && (
                  <div className="BillingAddInputField ">
                    <label className="BillingAddInputLabel">Head Office</label>
                    <Controller
                      control={control}
                      name="headOffice"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <input
                          type="text"
                          className="billing_input_field"
                          value={value}
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                )}
                {/* {companyName === null && ( */}
                {companyName ? (
                  <div className="BillingAddInputField ">
                    <label className="BillingAddInputLabel">
                      Branch Office
                    </label>
                    <Controller
                      control={control}
                      name="subOffice"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <select
                          value={value}
                          onChange={onChange}
                          className="billing_input_field"
                          // placeholder="Select"
                          style={{
                            "text-transform": "capitalize",
                          }}
                        >
                          <option value=""></option>
                          {subOfficeName?.map((data, index) => (
                            <option key={index} value={data?.shopName}>
                              {data.shopName}
                            </option>
                          ))}
                        </select>
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                ) : (
                  <div className="BillingAddInputField ">
                    <label className="BillingAddInputLabel">
                      Branch Office
                    </label>
                    <Controller
                      control={control}
                      name="subOffice"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <input
                          type="text"
                          className="billing_input_field"
                          value={value}
                        />
                      )}
                      // rules={{ required: true }}
                    />
                  </div>
                )}
              </div>

              {fields.map((field, index) => {
                let addSalesValues =
                  Number(userForm && userForm[index]?.UnitPrice) *
                    Number(userForm[index]?.Quantity) -
                  Number(userForm[index]?.DiscountPrice);
                console.log("USEFORM_addSalesValues", addSalesValues);
                userForm[index].ActualUnitPrice = addSalesValues;
                return (
                  <div className="d-flex flex-wrap">
                    {/* <div className="BillingAddAppendMainBlock"> */}
                    <div className="BillingAddAppendField">
                      <div className="BillingAddAppendInputContainer">
                        <label className="BillingAddInputLabels">
                          Product
                          <span style={{ color: "red", fontSize: "14px" }}>
                            *
                          </span>
                        </label>
                        <div>
                          <Controller
                            control={control}
                            name={`userForm.${index}.productName`}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <Autocomplete
                                value={value && value ? value : ""}
                                onChange={(event, newValue) => {
                                  setShouldFetch(true);
                                  onChange(newValue);
                                }}
                                disabled={value && value ? true : false}
                                className="purchaseSelect_input_field css-2avex9-MuiAutocomplete-root .MuiOutlinedInput-root"
                                disablePortal
                                id="combo-box-demo"
                                options={
                                  filterProductName
                                    ? filterProductName.map((data) => {
                                        return data?.productNameEnglish;
                                      })
                                    : []
                                }
                                sx={{ height: 40 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    value={value && value ? value : "none"}
                                    onChange={(e) => onChange(e.target.value)}
                                    error={!!error}
                                    // helperText={
                                    //   error ? "This field is required" : ""
                                    // }
                                  />
                                )}
                              />
                            )}
                            rules={{ required: true }}
                          />
                        </div>
                        {/* <input {...register(`userForm.${index}.Product`)} ></input> */}
                      </div>
                      <div className="BillingAddAppendInputContainer">
                        <label className=" BillingAddInputLabels">
                          Services
                        </label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.descripTion`}
                          // name="customerAddress"
                          render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error },
                          }) => (
                            <textarea
                              value={value}
                              onChange={onChange}
                              // {...field}
                              className="billing_input_field"
                            ></textarea>
                          )}
                          // rules={{ required: true }}
                        />
                      </div>
                      <div className="BillingAddAppendInputContainer">
                        <label className="BillingAddInputLabels ">HSN CD</label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.hsd`}
                          // name="quantity"
                          render={({ field: { onChange, value } }) => (
                            <input
                              {...field}
                              // placeholder="Quantity"
                              className="billing_input_field"
                              type="text"
                              value={value}
                              onChange={onChange}
                              // onChange={(e) => {
                              //   onChange(e);
                              //   // calculateQuantity(index);
                              // }}
                            />
                          )}
                        />
                      </div>
                      <div className="BillingAddAppendInputContainer">
                        <label className="BillingAddInputLabels ">
                          Quantity
                          <span style={{ color: "red", fontSize: "14px" }}>
                            *
                          </span>
                        </label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.quantity`}
                          // name="quantity"
                          render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error },
                          }) => (
                            <input
                              {...field}
                              style={error ? { borderColor: "red" } : {}}
                              // placeholder="Quantity"
                              className="billing_input_field"
                              type="number"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                calculateQuantity(index);
                              }}
                            />
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                      <div className="BillingAddAppendInputContainer">
                        <label className="BillingAddInputLabels">
                          Quantity Type
                          <span style={{ color: "red", fontSize: "14px" }}>
                            *
                          </span>
                        </label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.quantityType`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error },
                          }) => (
                            <select
                              value={value}
                              className="ps-0 billing_input_field "
                              onChange={onChange}
                              style={error ? { borderColor: "red" } : {}}
                            >
                              <option value=""> </option>
                              {[
                                ...new Set(
                                  detail?.map((data) => data.quantityType)
                                ),
                              ].map((quantityType, index) => (
                                <option key={index} value={quantityType}>
                                  {quantityType}
                                </option>
                              ))}
                              {/* {detail?.map((data, index) => (
                                <option key={index} value={data.quantityType}>
                                  {data.quantityType}
                                </option>
                              ))} */}
                            </select>
                          )}
                          rules={{ required: true }}
                        />
                      </div>
                      <div className="BillingAddAppendInputContainer">
                        <label className="BillingAddInputLabels">
                          Unit Price
                          <span style={{ color: "red", fontSize: "14px" }}>
                            *
                          </span>
                        </label>
                        <Controller
                          control={control}
                          // name="unitPrice"
                          name={`userForm.${index}.unitPrice`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error },
                          }) => (
                            <input
                              style={error ? { borderColor: "red" } : {}}
                              {...field}
                              // placeholder="Unite Price"
                              className="billing_input_field"
                              type="number"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                calculateQuantity(index);
                              }}
                            />
                            // render={(field) => (
                            // <BillingAddProductField
                            //   // billingInputCss="--billingInputCss"
                            //   // className='billingAddProductField'
                            //   {...field}
                            //   placeholder="0.00"
                            //   type="number"
                            // />
                          )}
                          rules={{ required: true }}
                        />
                        {/* <input {...register()} type="number"></input> */}
                      </div>

                      <div className="BillingAddAppendInputContainer">
                        <label className="BillingAddInputLabels">
                          Discount Price
                        </label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.discountPrice`}
                          // name="discountPrice"
                          className="discount"
                          render={({ field: { onChange, value } }) => (
                            <input
                              {...field}
                              // placeholder="Discount Price"
                              className="billing_input_field"
                              type="number"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                calculateQuantity(index);
                              }}
                            />
                          )}
                        />
                        {/* <input {...register(`userForm.${index}.DiscountPrice`)} type="number"></input> */}
                      </div>

                      <div className="BillingAddAppendInputContainer">
                        <label className="BillingAddInputLabels">
                          Actual Unit Price
                        </label>
                        <Controller
                          control={control}
                          name={`userForm.${index}.actualPrice`}
                          // name="actualPrice"
                          defaultValue={addSalesValues}
                          render={({ field: { onChange, value } }) => (
                            <input
                              {...field}
                              className="billing_input_field"
                              type="number"
                              value={value}
                              onChange={(e) => {
                                onChange(e);
                                calculateQuantity(index);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="d-flex align-items-center">
                        {index !== 0 ? (
                          <img
                            style={{ width: "40px", height: "45px" }}
                            src={deleteIcon}
                            alt="DELETE"
                            {...register(`userForm.${index}.image`)}
                            // onClick={() => remove(index)}
                            onClick={() => removeTask(index)}
                          />
                        ) : (
                          <></>
                        )}
                        <div>
                          {index < 0 ? (
                            <></>
                          ) : (
                            <>
                              <div
                                style={{
                                  height: "100px",
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: "10px",
                                }}
                              >
                                <img
                                  src={Add}
                                  alt=""
                                  className=" BillingAdd-AddBtn"
                                  onClick={() => {
                                    append({
                                      Product: " ",
                                      UnitPrice: 0,
                                      DiscountPrice: 0,
                                      Quantity: 0,
                                      ActualUnitPrice: 0,
                                      quantityType: " ",
                                    });
                                  }}
                                ></img>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div />
            </div>

            <div className="ms-3 BillingAddSubmitBlock">
              <div>
                <button type="submit" className="BillingAddSubmitBtn">
                  {isLoading ? (
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "save"
                  )}
                </button>
              </div>
            </div>
            {/* </div> */}
          </form>
        </div>
      </div>
      {/* </div> */}
      <AlertModal
        open={open}
        setOpen={setOpen}
        // handleClose={handleClose}
        setUpdateOpen={setUpdateOpen}
        UpdateOpen={UpdateOpen}
        navigate={"/BillingView"}
        setErrorOpen={setErrorOpen}
        errorOpen={errorOpen}
      />
    </>
  );
};

export default BillingAdd;
